<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">{{ `${salesPayment.code}` }} - {{ $t("COMMON.LOGS") }}</h3>
    <log-list-table :filterSubject="salesPayment" />
  </div>
</template>
<script>
import LogListTable from "@/views/Pages/DefaultModule/LogManagement/partials/LogListTable.vue";

export default {
  name: "salesPayment-view-logs",

  components: { LogListTable },

  props: ["salesPayment"],

  mixins: [],

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {
    user(user) {},
  },
};
</script>
