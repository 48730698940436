<template>
  <div class="container-fluid">
    <salesPayment-form
      :loading="loading"
      :salesPaymentData="salesPayment"
      :formErrors="formErrors"
      @salesPaymentSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultSalesPayment from "../defaultSalesPayment";
import SalesPaymentForm from "../partials/SalesPaymentForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    SalesPaymentForm,
  },

  mixins: [alertLeave],

  data() {
    return {
      salesPayment: cloneDeep(defaultSalesPayment),
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(salesPaymentData) {
      this.loading = true;
      try {
        await this.$store.dispatch("salesPayments/add", salesPaymentData);
        this.$notify({
          type: "success",
          message: this.$t("SALES_PAYMENTS.SALES_PAYMENT_ADDED"),
        });
        const salesPayment = await this.$store.getters[
          "salesPayments/salesPayment"
        ];
        this.$emit("onViewSalesPayment", salesPayment);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
